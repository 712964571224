import { Spinner as BPSpinner } from "@blueprintjs/core"
import styled from "@emotion/styled"

const Spinner = styled(BPSpinner)`
  &.bp4-spinner .bp4-spinner-track {
    stroke: ${(props) => props.color};
  }
  &.bp4-spinner .bp4-spinner-head {
    stroke: ${(props) => props.headcolor};
  }
`

type ColorSpinnerProps = {
  color?: string
  headcolor?: string
  size?: number
}

const ColorSpinner = ({
  color,
  headcolor,
  size,
  ...props
}: ColorSpinnerProps) => (
  <Spinner {...props} color={color} headcolor={headcolor} size={size} />
)

export default ColorSpinner
