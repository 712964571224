import { ReactNode } from "react"
import { Button, Colors, Menu, MenuDivider, Position } from "@blueprintjs/core"
import { Popover2, MenuItem2 as MenuItem } from "@blueprintjs/popover2"
import styled from "@emotion/styled"
import { AuthContext } from "helpers/contexts"
import OrganizationIcon from "icons/organization.svg"
import Link from "next/link"
import Router from "next/router"

import { useContext } from "react"

import { useCurrentUser } from "features/auth/withAuth"
import { useUnsavedChanges } from "helpers/hooks"

const UserMenuEmail = styled.span`
  color: ${Colors.GRAY1};
`

type UserPopoverMenuProps = {
  email?: string
}

const UserPopoverMenu = ({ email }: UserPopoverMenuProps) => {
  const { hasUnsavedChanges, setShowUnsavedChangesModal } = useUnsavedChanges()
  const { logoutAsync } = useContext(AuthContext)
  return (
    <Menu>
      <MenuItem
        text={<UserMenuEmail>{email}</UserMenuEmail>}
        onClick={() =>
          hasUnsavedChanges ? setShowUnsavedChangesModal(true) : null
        }
      />

      <MenuDivider />

      <Link href={hasUnsavedChanges ? "#" : "/account"} passHref>
        <MenuItem
          text="Account"
          onClick={() => hasUnsavedChanges && setShowUnsavedChangesModal(true)}
        />
      </Link>
      <MenuItem
        text="Logout"
        onClick={() =>
          hasUnsavedChanges
            ? setShowUnsavedChangesModal(true)
            : logoutAsync?.("logout")
        }
      />
    </Menu>
  )
}

function HelpPopoverMenu() {
  let { currentUser } = useCurrentUser()

  let org = currentUser?.organizationData

  return org ? (
    <Menu>
      <MenuItem
        href={String(org.custom_help_url)}
        text="Support and FAQ"
        target="_blank"
        rel="noreferrer"
      />
      <MenuItem
        target="_blank"
        rel="noopener noreferrer"
        href={`mailto:${org.custom_help_email}?subject=SimplyAgree support request`}
        text={`Contact: ${org.custom_help_email}`}
      />
    </Menu>
  ) : null
}

const Navbar = styled.nav<{ fullWidth: boolean }>`
  grid-row: 1;
  grid-column: ${(props) => (props.fullWidth ? "span 3" : "span 2")};
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding-left: ${(props) => (props.fullWidth ? "" : "48px")};
  padding-right: 49px;
  min-width: 600px;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`

const NavbarSegment = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`

const HelpSectionWrapper = styled.div`
  margin-right: 4px;
`

export const AdminSection = () => {
  const { hasUnsavedChanges, setShowUnsavedChangesModal } = useUnsavedChanges()
  return (
    <Popover2
      content={
        <Menu>
          <MenuItem
            onClick={() =>
              hasUnsavedChanges
                ? setShowUnsavedChangesModal(true)
                : Router.push("/admin/transaction-activity")
            }
            text="Transaction Activity"
          />
          <MenuItem
            onClick={() =>
              hasUnsavedChanges
                ? setShowUnsavedChangesModal(true)
                : Router.push("/admin/member-management")
            }
            text="Member Management"
          />
        </Menu>
      }
      position={Position.BOTTOM_RIGHT}
    >
      <Button
        className="mr-1"
        minimal
        icon={<OrganizationIcon height={15} width={15} viewBox="0 0 20 22" />}
      />
    </Popover2>
  )
}

export const HelpSection = () => (
  <HelpSectionWrapper>
    <Popover2 content={<HelpPopoverMenu />} position={Position.BOTTOM_RIGHT}>
      <Button minimal icon="help" />
    </Popover2>
  </HelpSectionWrapper>
)

type UserSectionProps = {
  email?: string
}

export const UserSection = ({ email }: UserSectionProps) => (
  <Popover2
    content={<UserPopoverMenu email={email} />}
    position={Position.BOTTOM_RIGHT}
  >
    <Button minimal icon="user" />
  </Popover2>
)

type NavigationProps = {
  children?: ReactNode
  email?: string
  fullWidth?: boolean
}

export const Navigation = ({
  children,
  email,
  fullWidth = false,
}: NavigationProps) => {
  const { user } = useContext(AuthContext)

  return (
    <Navbar fullWidth={fullWidth}>
      <NavbarSegment className="min-w-0">{children}</NavbarSegment>

      <NavbarSegment>
        {user?.is_org_admin && <AdminSection />}

        {user?.organizationData?.custom_help_url && <HelpSection />}

        <UserSection email={email || user?.email} />
      </NavbarSegment>
    </Navbar>
  )
}

export default Navigation
