import { ReactNode } from "react"
import {
  Children,
  cloneElement,
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
} from "react"

import { motion, AnimatePresence } from "framer-motion"
import { Global, css } from "@emotion/core"
import { Position, PopoverInteractionKind } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import styled from "@emotion/styled"

import { useInsideClick } from "helpers/hooks"

export const TabContext = createContext<{
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  resetSelectedIndex: () => void
}>({
  selectedIndex: -1,
  setSelectedIndex: () => {},
  resetSelectedIndex: () => {},
})

const MenuLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  background: white;

  img {
    height: 24px;
    width: 24px;
  }
`

export const MenuListContainer = styled.nav<{
  inverted?: boolean
  dark?: boolean
}>`
  width: 48px;
  grid-column: 1;
  grid-row: 1 / span2;

  ${(props) =>
    props.inverted &&
    "background: linear-gradient(141deg, #5A829E 0%, #3C5D74 99%)"}
  ${(props) =>
    props.dark &&
    "background: linear-gradient(141deg, #30404D 0%, #293742 100%)"}
  ${(props) => !props.inverted && !props.dark && "white"};

  display: flex;
  flex-direction: column;

  & > div > svg {
    fill: ${(props) => (props.inverted || props.dark ? "white" : "black")};
  }
`

const NavItem = styled.div<{
  selected?: boolean
  inverted?: boolean
  dark?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: 100%;
  height: 48px;
  margin-bottom: 0.5rem;

  ${(props) =>
    props.selected &&
    `
      & :only-child {
        position: relative;
        left: -1px;
      }

      border-left: 4px ${
        props.inverted || props.dark ? "white" : "#5A829E"
      } solid;
      background: ${
        props.inverted || props.dark
          ? "hsla(360, 100%, 100%, 0.1)"
          : "rgba(90, 130, 158, 0.10)"
      }
  `}
`

// A property that's inspected later to see if this item should be
// selectable
NavItem.selectable = true

export const PanelContainer = styled(motion.div)`
  width: 411px;
  background-image: linear-gradient(-35deg, #3c5d74 9%, #5a829e 100%);
  color: white;
  height: 100%;
  overflow: auto;

  & > div {
    padding: 12px 48px;
    display: flex;
    flex-direction: column;
  }

  & .button-sidebar {
    color: white;
    opacity: 50%;

    & :hover {
      color: white;
      opacity: 100%;
    }
  }
`

const BackgroundShadow = styled(motion.div)`
  content: "";
  position: fixed;
  top: 48px;
  left: 48px;
  height: calc(100% - 48px);
  width: calc(100% - 48px);
  background: #10161a;
  opacity: 70%;
  z-index: -1;
`

const PanelTitle = styled.div`
  font-size: 22px;
  color: #ffffff;
  letter-spacing: 1px;
  line-height: 25px;
  margin-bottom: 32px;
  text-align: center;
`

const PanelsWrapper = styled.div<{
  expandable?: boolean
  fullHeight?: boolean
}>`
  ${(props) =>
    props.expandable &&
    `
  position: fixed;
  z-index: 11;
  top: 48px;
  left: 48px;
  height: calc(100% - 48px);
  `}

  ${(props) =>
    !props.fullHeight &&
    `
    height: 100%;
    grid-row: 1/-1;
    grid-column: span 1;
  `}
`

type SidebarProps = {
  children?: ReactNode
  initial?: number
}

const Sidebar = ({ children, initial }: SidebarProps) => {
  const [selectedIndex, setSelectedIndex] = useState(initial || 0)

  const resetSelectedIndex = () => {
    setSelectedIndex(0)
  }

  return (
    <TabContext.Provider
      value={{ selectedIndex, setSelectedIndex, resetSelectedIndex }}
    >
      {children}
    </TabContext.Provider>
  )
}

type NavItemCollectionProps = {
  children?: ReactNode
  dark?: boolean
  inverted?: boolean
  alwaysExpanded?: boolean
}

const NavItemCollection = ({
  children,
  dark = false,
  inverted = false,
  alwaysExpanded = false,
}: NavItemCollectionProps) => {
  const { setSelectedIndex, selectedIndex, resetSelectedIndex } =
    useContext(TabContext)

  const selectListItem = (index: number) => () => {
    // Only update the selection when choosing a new value
    if (selectedIndex !== index) {
      setSelectedIndex(index)
    } else {
      // Only update if alwaysExpanded is not set to true
      if (!alwaysExpanded) {
        resetSelectedIndex()
      }
    }
  }

  useEffect(() => {
    Children.forEach(children, (child, index) => {
      // set the last child to have a defaultSelected property as the
      // selected value on the first render.
      if (child?.props.defaultSelected) {
        setSelectedIndex(index)
      }
    })
  }, [])

  return (
    <MenuListContainer inverted={inverted} dark={dark}>
      {Children.map(children, (child, index) => {
        let isNavItemComponent = child?.type?.name === "NavItemComponent"
        return child
          ? cloneElement(child, {
              onClick: child.type.selectable ? selectListItem(index) : null,
              selected: selectedIndex === index,
              inverted: isNavItemComponent ? inverted : undefined,
              dark: isNavItemComponent ? dark : undefined,
            })
          : null
      })}
    </MenuListContainer>
  )
}

type NavItemComponentProps = {
  children?: ReactNode
  description?: string
  selected?: boolean
  defaultSelected?: boolean
}

function NavItemComponent({ description, ...props }: NavItemComponentProps) {
  let navComponent = <NavItem {...props} />

  if (description && !props.selected) {
    return (
      <Popover2
        content={
          <div className="color-blue-6 px-3 py-2 tracking-wider">
            {description}
          </div>
        }
        hoverCloseDelay={0}
        interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
        minimal
        position={Position.RIGHT}
        targetTagName="div"
      >
        {navComponent}
      </Popover2>
    )
  }

  return navComponent
}

NavItemComponent.selectable = true

const PanelContentsVariants = {
  expanded: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
  collapsed: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
}

type MenuPanelProps = {
  title?: string
  children?: ReactNode
}

/**
 * Displays formatted content in a panel.
 *
 * @param {string} title - The Title of the panel.
 * @param {ReactNode} children - Next JSX children.
 */
const MenuPanel = ({ title, children }: MenuPanelProps) => {
  return (
    <motion.div
      animate="expanded"
      initial="collapsed"
      exit="collapsed"
      variants={PanelContentsVariants}
    >
      <PanelTitle>{title}</PanelTitle>
      <div>{children}</div>
    </motion.div>
  )
}

MenuPanel.isPanel = true

const MainPanelVariants = {
  expanded: {
    width: "411px",
    opacity: 1,
    transition: {
      duration: 0.2,
      staggerChildren: 0.2,
    },
  },
  collapsed: {
    width: "0",
    opacity: 0,
    transition: {
      duration: 0.2,
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
}

const BackgroundVariants = {
  expanded: {
    background: "rgba(0, 0, 0, 0.3)",
  },
  collapsed: {
    background: "rgba(0, 0, 0, 0)",
  },
}
type MenuPanelCollectionProps = {
  children?: ReactNode
  fullHeight?: boolean
  expandable?: boolean
}

const MenuPanelCollection = ({
  children,
  fullHeight = false,
  expandable = false,
}: MenuPanelCollectionProps) => {
  const ref = useRef<HTMLDivElement>(null)

  // Collapses panel if the background drop shadow is click
  useInsideClick(ref, () => resetSelectedIndex())

  const { selectedIndex, resetSelectedIndex } = useContext(TabContext)
  const panelIndex = selectedIndex - 1
  const childArray = Children.toArray(children)
  const Panel = childArray[panelIndex]

  const isValidPanel = Panel?.type?.isPanel

  return (
    <PanelsWrapper expandable={expandable} fullHeight={fullHeight}>
      {expandable && (
        <Global
          styles={
            isValidPanel
              ? css`
                  body {
                    overflow: hidden;
                  }
                `
              : undefined
          }
        />
      )}

      <AnimatePresence>
        {isValidPanel && expandable && (
          <BackgroundShadow
            key="background-shadow"
            animate="expanded"
            initial="collapsed"
            exit="collapsed"
            variants={BackgroundVariants}
            ref={ref}
          />
        )}

        {isValidPanel && (
          <PanelContainer
            key="panel-container"
            initial={expandable && "collapsed"}
            animate="expanded"
            exit="collapsed"
            variants={MainPanelVariants}
          >
            <AnimatePresence exitBeforeEnter initial={false}>
              {cloneElement(Panel, {
                key: `panel-${panelIndex}`, // panels have a fixed index
                fullHeight,
                expandable,
                index: panelIndex,
              })}
            </AnimatePresence>
          </PanelContainer>
        )}
      </AnimatePresence>
    </PanelsWrapper>
  )
}

Sidebar.Logo = MenuLogo
Sidebar.Menu = NavItemCollection
Sidebar.MenuItem = NavItemComponent
Sidebar.Panels = MenuPanelCollection
Sidebar.MenuPanel = MenuPanel

export default Sidebar
