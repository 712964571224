import * as React from "react"

import Portal from "components/portal"

type LightBoxProps = {
  children?: React.ReactNode
  onClickOutside?: () => void
  usePortal?: boolean
}

export default function LightBox({
  children,
  onClickOutside = () => null,
  usePortal = true,
}: LightBoxProps) {
  let Component = usePortal ? Portal : React.Fragment

  return (
    <Component>
      <div
        className="sa-lightbox fixed inset-0 z-20 flex overflow-auto bg-gray-950 bg-opacity-70"
        onClick={onClickOutside}
      >
        <div
          className="relative m-auto py-8"
          onClick={(evt) => evt.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </Component>
  )
}
