type LogoProps = {
  width?: any
  height?: any
}

export default function Logo({ width, height }: LogoProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 516.14 529.79"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>SimplyAgree-Icon</title>
      <path
        fill="#5A829E"
        d="m293.92,469.01c72.71-41.98,97.89-134.66,56.67-207.6L85.8,414.29c42.56,72.16,135.41,96.7,208.12,54.72Z"
      />
      <path
        fill="#5A829E"
        d="m430.34,115.5c-42.56-72.17-135.41-96.7-208.12-54.72-72.71,41.98-97.89,134.66-56.67,207.6L430.34,115.5Z"
      />
    </svg>
  )
}
