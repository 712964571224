import * as React from "react"

import styled from "@emotion/styled"
import { Icon } from "@blueprintjs/core"
import LightBox from "../../LightBox"
import cx from "classnames"
import { BlueprintIcons_16Id } from "@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16"

export const Modal = styled("div")`
  box-shadow:
    0 0 0 1px rgb(16 22 26 / 10%),
    0 4px 8px rgb(16 22 26 / 20%),
    0 18px 46px 6px rgb(16 22 26 / 20%);
  pointer-events: all;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  animation: scaleIn 250ms ease-in-out;

  @keyframes scaleIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`

type SAModalProps = {
  className?: string
  body?: React.ReactNode
  icon?: React.ReactNode | string
  onClose?: () => void
  title?: React.ReactNode
  iconCenter?: boolean
  canOutsideClickClose?: boolean
  isOpen?: boolean
  iconType?: "DEFAULT" | "WARNING" | "DANGER" | "WHITE"
  lightBoxProps?: Record<string, any>
}

export default function SAModal({
  body,
  icon,
  iconCenter,
  title,
  canOutsideClickClose = true,
  onClose = () => null,
  isOpen = true,
  iconType = "DEFAULT",
  lightBoxProps = {},
}: SAModalProps) {
  const iconPosition = cx("z-10", {
    "w-full flex items-center justify-center": iconCenter,
    "absolute -right-7 -top-7": !iconCenter,
  })

  const iconParent = cx("bg-white p-2 rounded-full", {
    "absolute -top-10 flex items-center justify-center h-20 w-20": iconCenter,
    "h-14 w-14": !iconCenter,
  })

  const iconBackground = cx(
    "bg-gradient-to-br h-full w-full rounded-full flex items-center justify-center text-white",
    {
      "from-blue-6 to-blue-9": iconType === "DEFAULT",
      "from-yellow-6 to-yellow-9": iconType === "WARNING",
      "from-red-6 to-red-9": iconType === "DANGER",
      "from-white to-white": iconType === "WHITE",
    }
  )

  const titleTopPadding = cx({
    "pt-5": iconCenter,
    "pt-4": !iconCenter,
  })

  const titleMarginTop = cx("font-semibold text-lg px-5", {
    "mt-6": iconCenter,
  })

  if (!isOpen) return null

  return (
    <LightBox
      {...lightBoxProps}
      onClickOutside={canOutsideClickClose ? onClose : undefined}
    >
      <Modal className="relative mx-8 rounded-sm bg-white p-0">
        {icon && (
          <div className={iconPosition}>
            <div className={iconParent}>
              <div className={iconBackground}>
                {icon === "string" ? (
                  <Icon icon={icon as BlueprintIcons_16Id} />
                ) : (
                  icon
                )}
              </div>
            </div>
          </div>
        )}

        <div className={titleTopPadding}>
          {title && typeof title === "string" ? (
            <div className={titleMarginTop}>{title}</div>
          ) : (
            title
          )}
          {body}
        </div>
      </Modal>
    </LightBox>
  )
}
